import React from 'react'

const LCOCrossfire = () => {
  return (
    <div>
      <p>Utilizing advanced technology, this pump offers unmatched accuracy, reliability, and performance, ideal for industries optimizing operations and cutting costs. With its compact design, easy installation, and low maintenance, the LCO Crossfire Injection Pump is a valuable investment for organizations enhancing productivity and profitability while reducing environmental impact.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Methane Emissions Elimination: Generates carbon offsets and credits with quick ROI</li>
 	<li class="li1">Production Optimization:
<ul class="ul1">
 	<li class="li1">Minimizes chemical waste</li>
 	<li class="li1">Delivers chemical proportionate to flow</li>
 	<li class="li1">Replaces up to 4 pneumatic pumps (1-720L/Day up to 10000PSI)</li>
</ul>
</li>
 	<li class="li1">Improved Reliability:
<ul class="ul1">
 	<li class="li1">MODBUS capabilities for automation and SCADA connectivity</li>
 	<li class="li1">Multiple control and automation options</li>
 	<li class="li1">Optional Bluetooth connectivity</li>
</ul>
</li>
 	<li class="li1">Solar-Powered Ideal for Remote Locations</li>
 	<li class="li1">Volume Tracking
<ul class="ul1">
 	<li class="li1">On-board volume totalization for each head</li>
 	<li class="li1">Eliminates manual data collection</li>
</ul>
</li>
</ul>
      <h1>Applications:</h1>
      <ul class="ul1">
 	<li class="li1">Oil and Gas Production</li>
 	<li class="li1">Methane Emissions Reduction</li>
 	<li class="li1">Remote Locations</li>
 	<li class="li1">Automation and SCADA Connectivity</li>
 	<li class="li1">Volume Tracking</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/remote-automation-scada/low-power-environmental-solutions/lco-crossfire-injection-pump/">Product Page</a></li>
      </ul>
    </div>
  )
}

export default LCOCrossfire
