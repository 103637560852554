const Enardo2001 = () => {
  return (
    <div>
      <h1>Enardo™ 2000/2100 & 2500 Series</h1>
      <h1>Specifications:</h1>
      <ul>
        <li>Sizes 16" through 24" Model #2100 & #2500</li>
        <li>Sizes 4" through 24" Model #2000</li>    
        <li>Exceeds Leakage Standard of 1 SCH @ 90% Set Point</li>
        <li>Field Replaceable Components</li>  
        <li>Corrosion Resistant Coatings</li>
        <li>Certified Flow Capacities Per API</li>  
        <li>Available with ANSI, DIN and JIS Flanges</li>
      </ul> 
      <h1>Features:</h1>
      <ul>
        <li>Designed to provide emergency pressure control for storage tanks when exposed to abnormally high or low pressures</li>
        <li>Provides an emergency pressure relief opening for storage tanks when exposed to overpressures not handled by standard tank vents.</li>    
        <li>Provide high-capacity vacuum flow for emergency pump-out of product</li>
      </ul> 
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.emerson.com/en-us/catalog/enardo-model-2000" rel="noopener noreferrer">Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default Enardo2001
