
const Fisher200L = () => {
  return (
    <div>
      <p>With the Fisher easy-Drive actuator, you can have the confidence that your control valves will perform as expected, regardless of application conditions. This design is based on extensive field testing and application engineering expertise.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Zero Venting of Product to Atmosphere/No Bleed</li>
 	<li class="li1">Fail Last and Fail Safe</li>
 	<li class="li1">4-20mA, 1-5V, or Modbus</li>
 	<li class="li1">On/Off or Throttling</li>
 	<li class="li1">Low Power Requirements, Economical vs Competition</li>
 	<li class="li1">12 or 24VDC</li>
 	<li class="li1">Max Current Draw of 3.5A, 3 Amps Idle, 1.2 Amps No Load</li>
 	<li class="li1">Remote Monitoring Reduces Trips to the Field</li>
 	<li class="li1">Range:
<ul class="ul1">
 	<li class="li1">1” and 2” Fisher D3E and D4E valves</li>
 	<li class="li1">2"ET and 2" D Bodies w/200L</li>
 	<li class="li1">Thrust up to 750 lbf</li>
</ul>
</li>
 	<li class="li1">Modulation:
<ul class="ul1">
 	<li class="li1">Rated for 50% duty cycle</li>
 	<li class="li1">0.5% accuracy</li>
</ul>
</li>
 	<li class="li1">Protocols: Modbus™ (serial or USB modem), Digital Aux 1/0, 4-20 mA</li>
</ul>
      <h1>Applications:</h1>
      <ul class="ul1">
 	<li class="li1">Upstream, midstream, and downstream processes in pipelines, refineries, and petrochemical plants</li>
 	<li class="li1">Valve control – power plants, material handling, machining, assembly process, cooling water, fuel handling</li>
 	<li class="li1">Liquid Level Control Valves</li>
 	<li class="li1">Back Pressure Control Valves</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/fisher-easydrive-electric-actuator/" target="_blank" rel="noopener noreferrer">Fisher easy-Drive & 200L Actuator</a></li>
      </ul>
    </div>
  )
}

export default Fisher200L
