const ServiceTrucks = () => {
  return (
    <div>
      <h1>Enabling our customers to optimize their assets with our full suite of Field &amp; Shop Service capabilities:</h1>
      <ul>
 	<li>Control Valve Life Cycle Services</li>
 	<li>Isolation Valve Expanded Services</li>
 	<li>Protection (PRV/SRV/PRVR) Expanded Services</li>
 	<li>Measurement &amp; Instrumentation (MI) Services</li>
</ul>
      <h1>Onsite Services:</h1>
      <ul>
 	<li>Mobile on-site capabilities</li>
 	<li>Site audits/walkdowns</li>
 	<li>Repair, on site greasing and servicing of valves</li>
 	<li>Commissioning</li>
 	<li>Actuator service and repair:</li>
 	<li>Electric, Pneumatic, Hydraulic, &amp;amp; Gas over Oil</li>
</ul>
      <h1>Spartans Services Infrastructure:</h1>
      <ul>
 	<li>Edmonton Service Centre
<ul>
 	<li>ASME Approved &amp;amp; Certified Assembly Facility</li>
 	<li>ABSA approved AQP program for isolation valve modification, welding, machining and trim changes</li>
</ul>
</li>
 	<li>Isolation, PSV, PVRV, MI, Control repair and testing</li>
 	<li>Meter Proving Stand-up to 8&amp;quot;</li>
 	<li>5 Regional Service Centers</li>
 	<li>7 Mobile On-Site Service vehicles</li>
</ul>
    </div>
  )
}
  
export default ServiceTrucks
