const PenberthyGauges = () => {
  return (
    <div>
      <p>Penberthy Models RL and TL Low-Pressure Flat Glass Gages are a reliable and cost-effective solution for monitoring liquid levels. Their rugged design, accurate readings, and easy-to-read flat glass make them an excellent choice for ensuring safe and efficient operations in low-pressure applications.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Reliable Performance: Ensuring accurate measurement over time</li>
 	<li class="li1">Easy to Understand Level Reference</li>
 	<li class="li1">Transparent Style for Visual Inspection</li>
 	<li class="li1">Non-Intrusive Operation</li>
 	<li class="li1">No Electrical Power Required</li>
 	<li class="li1">Suitable for Full Vacuum Applications</li>
 	<li class="li1">Near-Unlimited Length of Measure</li>
 	<li class="li1">Optional Offshore Coating 2600 Protection</li>
 	<li class="li1">NACE Wetted Materials Available</li>
 	<li class="li1">Used for Verification of Other Level Instrument Technology</li>
 	<li class="li1">Standard Flat Gasket Seat</li>
 	<li class="li1">Optional Shields for Prolonging Glass Life</li>
</ul>
      <h1>Appllications:</h1>
      <ul class="ul1">
 	<li class="li1">Monitoring fluid levels</li>
 	<li class="li1">Steam condensate measurement</li>
 	<li class="li1">Safety assurance</li>
 	<li class="li1">Process control optimization</li>
 	<li class="li1">Environmental compliance</li>
 	<li class="li1">Maintenance planning</li>
 	<li class="li1">Energy efficiency enhancement</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/steam-traps-valves-and-level-gauges/level-instrumentation/penberthy-models-rl-and-tl-low-pressure-flat-glass-gages/">Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default PenberthyGauges
