import React, { useEffect, useState } from 'react';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

const ProductViewer = ({ products, start, toggleVisibility }) => {
  const [index, setIndex] = useState(start);
  const [image, setImage] = useState('');
  const [length, setLength] = useState(1)

  useEffect(() => {
    if (products[start].image) {
      setImage(products[start].image.bg);
    }

    const productsWithoutImage = products.filter(product => !product.image);
    const count = productsWithoutImage.length; 
    setLength(count)
  }, [index])

  const change_index = (direction) => {
    const count = products.length - 1;
    let newIndex = 0;

    if (direction == "-") {
      newIndex = index + 1;

      if (newIndex > count) {
        newIndex = 0;
      }
    }

    if (direction == "+") {
      newIndex = index - 1;

      if (newIndex < 0) {
        newIndex = count;
      }
    }

    setIndex(newIndex)
  }
  
  return (
    <div className="grid-pattern flex animate-quickFade w-full h-full absolute top-0 left-0 right-0 bottom-0 z-50">
      {image ? (
        <>
          <img src={image} className='w-full h-full block relative z-10' alt={products[index].display.title1} />
          <div className='text-right absolute z-20 right-5 top-5'>
            <a className='button bg-darkblue bg-opacity-50 hover:bg-opacity-100 text-2xl inline-block mb-5 pt-1 pb-1 px-5' onClick={toggleVisibility}>Back</a>
          </div>
        </>
      ) : (
        <>
          {products[index].display && (
            <>
              <div className='w-45p pr-5 flex flex-col'>
                <div className='w-full text-right pt-5'>
                  <h1 className='xl:text-4xl lg:text-3xl text-2xl  pb-1 mb-1 border-b-2 border-solid border-darkblue' dangerouslySetInnerHTML={{ __html: products[index].display.title1 }}></h1>
                  <h1 className='xl:text-4xl lg:text-3xl text-2xl' dangerouslySetInnerHTML={{ __html: products[index].display.title2 }}></h1>
                </div>
                <div className='flex-grow flex flex-col items-end pb-7 pl-7'>
                  <div 
                    className='flex-grow w-full bg-contain bg-center bg-no-repeat -product-image' 
                    style={{
                      backgroundImage : 'url('+products[index].display.image+')'
                    }}>
                  </div>
                  {(length > 1) && (
                  <div className='special-height w-full flex justify-center'>
                    <button onClick={() => {change_index('+')}} className='w-5p flex justify-start items-center cursor-pointer'>
                      <FaChevronLeft className='text-primary lg:text-4xl text-2xl cursor-pointer' />
                    </button>
                    <Product_Thumbs />
                    <button onClick={() => {change_index('-')}} className='w-5p flex justify-end items-center cursor-pointer'>
                      <FaChevronRight className='text-primary lg:text-4xl text-2xl cursor-pointer' />
                    </button>
                  </div>
                  )}
                </div>
              </div>
              <div className='w-55p pt-5 pb-7 pr-7 pl-7 text-right flex flex-col items-end h-full'>
                <div className='button bg-darkblue bg-opacity-50 hover:bg-opacity-100 text-2xl inline-block mb-5 pt-1 pb-1 px-5 select-none' onClick={toggleVisibility}>Back</div>
                <div className='flex-grow py-8 pl-8 border-4 overflow-y-auto border-darkblue border-solid bg-white bg-opacity-80 w-full text-left pr-8 rounded-2xl scrollbar-thin'>
                  <div>
                    {products[index].display.content}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      
    </div>
  )

  function getItemsAroundIndex(arr, index, count) {
    let result = [];
    let len = arr.length;
    let half = Math.floor(count / 2);

    // Ensure the count doesn't exceed the array length
    count = Math.min(count, len);

    for (let i = -half; i <= half && result.length < count; i++) {
        let adjustedIndex = (index + i + len) % len;
        result.push({ 
            value: arr[adjustedIndex], 
            originalIndex: adjustedIndex 
        });
    }

    return result;
  }

  function Product_Thumbs() {
    const currentIndex = index;
    const count = products.length > 5 ? 5 : products.length;
    const thumbs = getItemsAroundIndex(products, index, count);

    return (
      <div className='flex-grow flex justify-center items-stretch'>
        {thumbs.map((product, index) => (
          <div key={index} className={`w-1/5 m-5 product-thumb ${ (product.originalIndex == currentIndex) ? 'active' : '' }`}>
            <div 
              onClick={() => {setIndex(product.originalIndex)}}
              key={index}
              className='bg-contain h-full bg-no-repeat bg-center cursor-pointer'
              style={{
                backgroundImage : 'url('+product.value.display.image+')'
              }}>
            </div>
          </div>
        ))}
      </div>
    )
  }
}



export default ProductViewer