const EnardoFlame = () => {
  return (
    <div>
      <p>Enardo™ Flame Arrestors offer reliable and effective protection against dangerous flame propagation in industrial processes, ensuring the safety of personnel and equipment while minimizing downtime and maximizing productivity.</p>
      <h1>Specifications:</h1>
      <ul>
        <li>Sizes - 1/2 through 36</li>
        <li>Housing Materials- Carbon Steel</li>    
        <li>Cell Materials- 304 SS</li>
        <li>Gas Groups- lIA (D)</li>  
        <li>Options- Drain Plugs, Pressure Taps, Misc. Fittings, Protective Coatings, Special Features</li>
      </ul> 
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Sizes: ½ “– 36”</li>
 	<li className="li1">Low Pressure Drop</li>
 	<li className="li1">Bi-Directional</li>
 	<li className="li1">Available with ANSI, DIN and JIS Flanges</li>
 	<li className="li1">Maximum Flow</li>
 	<li className="li1">Low Pressure Drop</li>
 	<li className="li1">Easy Cleaning</li>
 	<li className="li1">Less Maintenance</li>
 	<li className="li1"><b>Enardo™ Series 7 In-Line Flame Arrestor</b>
<ul className="ul1">
 	<li className="li1">Sizes: 1” - 48”</li>
 	<li className="li1">Single Element Design</li>
 	<li className="li1">Easily Removable Flame Cell</li>
 	<li className="li1">Gas Group: IIA (D)</li>
 	<li className="li1">Single element design</li>
</ul>
</li>
 	<li className="li1"><b>Enardo™ Series 8 High Pressure Deflagration Arrestor</b>
<ul className="ul1">
 	<li className="li1">Sizes: 1” - 36”</li>
 	<li className="li1">Designed for High Pressure Deflagration</li>
 	<li className="li1">Less Maintenance</li>
 	<li className="li1">Easily Removable Flame Cell</li>
</ul>
</li>
 	<li className="li1"><b>Enardo™ DFA Series Detonation Flame Arrestors</b>
<ul className="ul1">
 	<li className="li1">Sizes: ½ “ - 36”</li>
 	<li className="li1">Designed for Unstable Detonation</li>
 	<li className="li1">Easily Removable Flame Cell</li>
</ul>
</li>
 	<li className="li1">Enardo™ IL Series Threaded In-Line Flame Arrestors
<ul className="ul1">
 	<li className="li1">Sizes: ½ “ - 4”</li>
 	<li className="li1">Less Clogging</li>
 	<li className="li1">Single Element Design</li>
</ul>
</li>
 	<li className="li1">Enardo™ Series 8800 and 9900 Vent Stack Flame Arrestors
<ul className="ul1">
 	<li className="li1">Sizes: ½ “ - 6”</li>
 	<li className="li1">Less Clogging</li>
 	<li className="li1">Single Element Design</li>
</ul>
</li>
</ul>
<h1>Applications:</h1>
<ul>
  <li>End-of line applications</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-series-7-in-line-flame-arrestor/" target="_blank" rel="noopener noreferrer">
          Enadro Series 7 | Flame Arrestor - Spartan Controls 
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-series-8-high-pressure-deflagration-arrestor/" target="_blank" rel="noopener noreferrer">
          Enadro Series 8 | Flame Arrestors - Spartan Controls 
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-dfa-series-detonation-flame-arrestors/" target="_blank" rel="noopener noreferrer">
          Enardo DFA Series | Flame Arrestor - Spartan Controls 
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-il-series-threaded-in-line-flame-arrestors/" target="_blank" rel="noopener noreferrer">
          Enadro IL Series l Flame Arrestor - Spartan Controls 
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-series-8800-and-9900-vent-stack-flame-arrestors/" target="_blank" rel="noopener noreferrer">
          Enadro Series 8800 & 9900 | Flame Arrestor - Spartan Controls
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www3.emersonprocess.com/Regulators/Animation/Flame%20Arrestor/Flame%20Arrestor%20Animation.html" target="_blank" rel="noopener noreferrer">
          Flame Arrestor Animation (emersonprocess.com) 
          </a>
        </li>
      </ul>
    </div>
  )
}
  
export default EnardoFlame
