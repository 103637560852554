import React, { useEffect, useState } from 'react';

import Home from './components/Home';
import { videos, images, product_images } from './components/Data';
//import isElectron from './components/partials/isElectron';

function App() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  //const isElectronEnv = isElectron();

  useEffect(() => {
    /**
     * Doc title
     */
    document.title = 'Spartan Controls';

    // Preload the background image first
    const backgroundImg = new Image();
    backgroundImg.onload = () => {
      setBackgroundLoaded(true); // Set background as loaded
    };
    backgroundImg.src = "assets/stills/start.jpg";

    /**
     * preloading assets
     */
    if (backgroundLoaded) {
      const totalAssets = images.length + videos.length;
      let loadedAssets = 0;

      const updateProgress = () => {
        loadedAssets++;
        setProgress(Math.round((loadedAssets / totalAssets) * 100));
        if (loadedAssets === totalAssets) {
          setLoading(false);

          // Preload product images
          /*product_images.forEach(image => {
            const img = new Image();
            img.onload = updateProgress;
            img.onerror = updateProgress; // Consider image loaded even if there's an error
            img.src = image;
          });*/
        }
      };

      // Preload images
      images.forEach(image => {
        const img = new Image();
        img.onload = updateProgress;
        img.onerror = updateProgress; // Consider image loaded even if there's an error
        img.src = image;
      });

      // Preload videos
      videos.forEach(video => {
        fetch(video, { mode: 'no-cors' })
          .then(response => response.blob())
          .then(blob => {
            URL.createObjectURL(blob);
            updateProgress();
          })
          .catch(err => {
            console.log('Error preloading video:', err);
            updateProgress();
          });
      });
    }
    
    /**
     * Window resizing
     */
    // Handler to call on window resize
    function handleResize() {
      const theHeight = 844;
      const theWidth = 1500;

      let windowH = window.innerHeight;
      let windowW = window.innerWidth;
      let widthRatio = (theHeight * windowW) / theWidth;

      if ( widthRatio > windowH ) {
        windowW = (theWidth * windowH) / theHeight;
        widthRatio = windowH;
      }

      // Set window width/height to state
      setWindowSize({
        width: windowW,
        height: widthRatio,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [backgroundLoaded]); // Empty array ensures that effect is only run on mount

  /**
   * 
   */
  return (
    <main id='main' className='overflow-hidden relative' style={{height : windowSize.height, width: windowSize.width}}>
      {loading ? <LoadingScreen progress={progress} /> : <Home />}
    </main>
  );
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const LoadingScreen = ({ progress }) => (
  <div className='w-full h-full'>
    <div className="flex flex-col justify-center items-center w-full h-full absolute top-0 left-0 bottom-0 right-0 z-10">
      <h1 className='text-white font-bold'>Loading assets, please wait...</h1>
      <div className="w-4/5 max-w-3xl h-4 border mt-5 relative bg-gray-200">
        <div className="absolute top-0 left-0 h-full bg-primary" style={{ width: `${progress}%` }}></div>
      </div>
      <h2 className='mt-8 text-white font-bold'>{progress}%</h2>
    </div>
    <img src="assets/stills/start.jpg" alt='Home Background' className='w-full z-0' />
  </div>
);

export default App;