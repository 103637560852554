import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import { AiOutlineCloseCircle } from "react-icons/ai";

const VideoModal = ({ videoId, closeModal, video }) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const updateVideoSize = () => {
        const maxWidth = window.innerWidth * 0.8;  // Use up to 80% of the window width
        const maxHeight = window.innerHeight * 0.8;  // Use up to 80% of the window height
        const aspectRatio = 16 / 9;

        // Calculate maximum size respecting the aspect ratio
        let height = Math.min(maxHeight, maxWidth / aspectRatio);
        let width = height * aspectRatio;

        // Adjust width if it's still too wide for window's width
        if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
        }

        setDimensions({ width, height });
    };

    useEffect(() => {
        updateVideoSize();
        window.addEventListener('resize', updateVideoSize);
        return () => window.removeEventListener('resize', updateVideoSize);
    }, []);

    const opts = {
        height: dimensions.height,  // Dynamic height based on the aspect ratio
        width: dimensions.width,    // Dynamic width based on the aspect ratio
        playerVars: {
            autoplay: 1,  // This will auto-play the video when the modal opens
        },
    };

    return (
        <Modal
            isOpen={true}
            onRequestClose={closeModal}
            contentLabel="Video Modal"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '10000',
                    padding: '0',
                    width: Math.round(dimensions.width) + 'px',  // Round dimensions to avoid subpixel rendering issues
                    height: Math.round(dimensions.height) + 100 + 'px',
                    overflow: 'hidden',  // Prevent scroll bars,
                    backgroundColor: 'transparent',
                    border: 'none',
                    textAlign: 'center'
                },
                overlay: {
                    backgroundColor: 'rgba(62, 105, 184, 0.5)'  // Makes the overlay completely transparent
                }
            }}
        >
            {videoId ? (
                <YouTube videoId={videoId} opts={opts} onEnd={closeModal} />
            ) : (
                <video 
                    controls={true} 
                    preload="none" 
                    className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full z-20'
                    playsInline
                    autoPlay
                    src={video}
                    onEnded={closeModal}
                ></video>
            )}
            <div className='absolute top-0 right-0 z-50'>
                <AiOutlineCloseCircle onClick={closeModal} color='white' className='text-4xl cursor-pointer' />
                {/*<button className='py-3 px-5 rounded-sm border border-solid border-slate-400 bg-slate-400 text-white text-xl' onClick={closeModal}>Close</button>*/}
            </div>
        </Modal>
    );
};

export default VideoModal;
