
const FisherL2e = () => {
  return (
    <div>
      <p>ADD The Fisher L2e electric on/off level controller for reliable liquid level detection. Designed with a displacer-type sensor, it's ideal for oil and gas separators, treaters, and scrubbers. Its force-balanced design ensures consistent performance in critical applications.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Position Control: On/Off</li>
 	<li class="li1">Process Control: Level</li>
 	<li class="li1">Intuitive Zero and Span Adjustments for Flexible Loop Performance</li>
 	<li class="li1">Premium Hermetically Sealed Switch with Gold Contacts for Accurate Liquid Level Control</li>
 	<li class="li1">No Leakage Current</li>
 	<li class="li1">Controller Performance Maintains in High Vibration Installations</li>
 	<li class="li1">Displacer Adjustable for Vertical or Horizontal Operation Without Extra Parts</li>
 	<li class="li1">Sensor Easily Disassembled for Seal Inspection or Replacement</li>
 	<li class="li1">Field-Replaceable Controller with No Repairable Parts</li>
</ul>
      <h1>Specifications:</h1>
      <ul class="ul1">
 	<li class="li1"><b>Area Classification: Explosion Proof, Flame Proof, Dust Ignition Proof</b></li>
 	<li class="li1"><b>Certifications: CSA, ATEX, IECEx, PED, CUTR</b></li>
 	<li class="li1"><b>Data Interface: Wired</b></li>
 	<li class="li1"><b>Diagnostics: No</b></li>
</ul> 
<h1>Applications:</h1>
<ul class="ul1">
 	<li class="li1">Monitor liquid levels for separation of oil, gas, and water.</li>
 	<li class="li1">Regulate liquid levels to remove impurities from crude oil or natural gas</li>
 	<li class="li1">Ensure precise level control in production processes</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/controllers-and-instruments/level-sensors-and-controllers/fisher-l2e-electric-level-controller/" target="_blank" rel="noopener noreferrer">Product Page</a></li>
      </ul>
    </div>
  )
}

export default FisherL2e
