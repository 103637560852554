const EspiritCamera = () => {
  return (
    <div>
      <p>The Esprit® Camera Series offers responsive, high-definition, high-speed positioning capabilities, outstanding weather protection including wiper options, and ease of installation.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Three Levels of Fog Removal - Improved image quality during bad weather conditions</li>
 	<li className="li1">SureVision Technology: For advanced low-light and True Wide Dynamic Range performance</li>
 	<li className="li1">Optimized Image Clarity - In high-vibration environments</li>
 	<li className="li1">360° continuous pan rotation at 140° variable speed</li>
 	<li className="li1">Vivid Imaging Mode</li>
 	<li className="li1">Full HD 1080p 30x Optical Zoom Camera with Powerful SureVision 3.0 Technology</li>
</ul>
<h1>Applications:</h1>
<ul className="ul1">
 	<li className="li1">Security and surveillance</li>
 	<li className="li1">Available in standard or pressurized 30x optical zoom module for tough outdoor environments</li>
 	<li className="li1">Critical infrastructure protection</li>
 	<li className="li1">Border and perimeter security</li>
</ul>
      <h1>Links:</h1>
     
          <ul>
            <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.pelco.com/products/cameras/ptz-cameras/esprit-enhanced/">Pelco Esprit HD | Fire & Safety | Spartan Controls - Canada</a></li>
          </ul>
        
    </div>
  )
}
  
export default EspiritCamera
