
const BettisRTS = () => {
  return (
    <div>
      <p>The Bettis RTS-CL Compact Linear Electric Actuator, is a reliable and precise electric actuator designed for linear valve control applications. With its compact design, high thrust output, and robust construction, it delivers accurate and consistent linear movement for efficient valve automation in various industrial settings.</p>
      <h1>Features:</h1>
	  <ul class="ul1">
 	<li class="li1">Local User Interface with Wireless Bluetooth Control Capability</li>
 	<li class="li1">Highly Configurable and Intelligent Actuator with Options for Continuous Modulation</li>
 	<li class="li1">Compact &amp; Lightweight with Corrosion Protection</li>
 	<li class="li1">Intelligent and Non-intrusive</li>
 	<li class="li1">On/Off (S2) and Continuous Modulation (S9)</li>
 	<li class="li1">Monitoring, and Diagnostics</li>
 	<li class="li1">Certified Explosion and Weatherproof</li>
 	<li class="li1">Protocols: Modbus™, HART™, PROFIBUS™, FOUNDATION Fieldbus™, PROFINET™</li>
 	<li class="li1">Bettis RTS CL Compact Linear Electric Valve Actuator:
<ul class="ul1">
 	<li class="li1">Up to 25kN and 100mm Stroke</li>
 	<li class="li1">Adjustable Speed (2sec±54 sec)</li>
</ul>
</li>
 	<li class="li1">Bettis RTS CM Compact Multi-turn Electric Valve Actuator:
<ul class="ul1">
 	<li class="li1">Adjustable Speed (2sec-54 sec)</li>
 	<li class="li1">Brushless DC Motor Technology</li>
 	<li class="li1">Rotatable in 90 Degree Steps with Remote Mounting Option</li>
 	<li class="li1">User Application Available for Android Devices</li>
</ul>
</li>
</ul>
      <h1>Applications:</h1>
      <ul class="ul1">
 	<li class="li1">Wellhead Choke Valves: Angle body and in-line</li>
 	<li class="li1">Plunger / Gas Lift: Angle body chokes or D bodies</li>
 	<li class="li1">Separator inlet and back pressure control</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-cl-compact-linear-electric-actuator/" target="_blank" rel="noopener noreferrer">Bettis RTS CL Compact Linear Electric Valve Actuator</a></li>
		<li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-cm-compact-multi-turn-electric-actuator/" target="_blank" rel="noopener noreferrer">Bettis RTS CM Compact Multi-turn Electric Valve Actuator</a></li>
      </ul>
    </div>
  )
}

export default BettisRTS
