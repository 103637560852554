const MicroMotion = () => {
  return (
    <div>
      <p>Emerson's Micro Motion Coriolis meters provide a breadth of products that are tailored to your application needs. Designed to meet a variety of industry demands, Coriolis flow meters deliver the most accurate, repeatable mass flow measurement for liquids, gases or slurries.</p>
      <h1>Specifications:</h1>
      <ul>
        <li>Liquid Accuracy / Repeatability- Up to $0.1% of rate (0.05% of rate)</li>
        <li>Gas Accuracy / Repeatability- Up to $0.35% of rate (0.25% of rate)</li>    
        <li>Density Accuracy / Repeatability- Up to $0.0005 g/cm3 (0.0002 g/cm3)</li>
        <li>Line Size- 1/4" (DN6) - 4" (DN100)</li>  
        <li>Pressure Range- Up to 6250 psig (430 barg)</li>
        <li>Temperature Range --148 °F to 662 °F (-100 °C to 350 °C)</li>  
        <li>Electronics (soft) - Expansive 1/0 options including mA, Frequency, Discrete, HART, Modbus, Ethernet/IP, PROFINET, FOUNDATION Fieldbus</li>
        <li>Turndown- 20:1 Multiple Models</li>  
      </ul> 
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1"><span class="s2">Achieve highly accurate mass volume flow and density measurement in applications that require a compact, drainable design</span></li>
 	<li class="li1"><span class="s2">Choose from 316L Stainless Steel and C-22 nickel alloy in all sizes</span></li>
 	<li class="li1"><span class="s2">Improve measurement and tracking with extensive process and meter diagnostics with on-board historian and data logging</span></li>
 	<li class="li2"><span class="s2">Broad range of meter approvals and certifications, including, but not limited to; CSA (Class 1 Div 1 groups C and D, Class 1 Div 2 groups A, B, C and D), ATEX, IECEx, Ingress Protection 66/67, and NAMUR</span></li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/measurement-instrumentation/flow-measurement/coriolis/micro-motion-f-series-compact-drainable-coriolis-flow-and-density-meters/">Micro Motion F-Series Compact, Drainable Coriolis Flow and Density Meters</a></li>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/measurement-instrumentation/flow-measurement/coriolis/micro-motion-cng-coriolis-flow-meter/">Micro Motion CNG Coriolis Flow Meter</a></li>
      </ul>
    </div>
  )
}
  
export default MicroMotion
