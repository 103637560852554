const BettisRTSMulti = () => {
  return (
    <div>
      <p>The Bettis RTS-CL Compact Multi-turn Electric Actuator automates valve operation in industrial settings, reducing emissions and energy consumption for net zero wellpads. Its compact design and advanced diagnostic capabilities make installation and maintenance easy, while its fail-safe design ensures safety in emergencies.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Local User Interface with Wireless Bluetooth Control Capability</li>
 	<li className="li1">Highly Configurable and Intelligent Actuator with Options for Continuous Modulation and Mechanical Fail-Safe</li>
 	<li className="li1">Compact &amp; Lightweight with Corrosion Protection</li>
 	<li className="li1">Intelligent and Non-intrusive</li>
 	<li className="li1">On/Off (S2) and Continuous Modulation (S9)</li>
 	<li className="li1">Low power draw,.8mA idle / max 4.6 amp at full speed and torque/thrust</li>
 	<li className="li1">Advanced Optimization &amp; Control:</li>
 	<li className="li1">Precise Process Control - .1%</li>
 	<li className="li1">Monitoring, and Diagnostics</li>
 	<li className="li1">Certified Explosion and Weatherproof</li>
 	<li className="li1">Protocols: Modbus™, HART™, PROFIBUS™, FOUNDATION Fieldbus™, PROFINET™</li>
 	<li className="li1">Bettis RTS CM Compact Multi-turn Electric Valve Actuator:
<ul className="ul1">
 	<li className="li1">Adjustable Speed (2sec-54 sec)</li>
 	<li className="li1">Brushless DC Motor Technology</li>
 	<li className="li1">Rotatable in 90 Degree Steps with Remote Mounting Option</li>
 	<li className="li1">User Application Available for Android Devices</li>
</ul>
</li>
</ul>
      <h1>Applications:</h1>
      <ul>
        <li>Wellhead choke valves- angle body and in-line</li>
        <li>Plunger/gas Lift - angle body chokes</li>
      </ul>
      <p className="text-2xl"><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-cl-compact-linear-electric-actuator/" target="_blank" rel="noopener noreferrer">Bettis RTS CM Series Multi-turn Product Page</a></p>
    </div>
  )
}
  
export default BettisRTSMulti
