const BettisFL = () => {
  return (
    <div>
      <p>Leading provider of innovative True mechanical fail-safe spring technology and precise process control electric actuators that help companies achieve net-zero wellpads, ensuring unparalleled safety, efficiency, and reliability while minimizing environmental impact. Our actuators provide superior control and accuracy, enabling operators to achieve maximum efficiency with minimal waste and reduce energy consumption and carbon footprint.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Advanced Safety &amp; Reliability</li>
 	<li className="li1">Fail-Safe Design for Safety in Case of Power Failure or Emergency</li>
 	<li className="li1">True mechanical fail safe (Compressed spring)</li>
 	<li className="li1">No separate UPS, battery backup or supercapacitors required</li>
 	<li className="li1">Precise Control over Fluid and Gas Flow, Reducing Emissions and Energy Consumption</li>
 	<li className="li1">Easy Installation and Maintenance, Reducing Downtime and Costs</li>
 	<li className="li1">Robust Construction for Reliable Operation in Harsh Industrial Environments</li>
 	<li className="li1">Low power draw,.8mA idle / max 4.6 amp at full speed and torque/thrust</li>
 	<li className="li1">Advanced Optimization &amp; Control:</li>
 	<li className="li1">Precise Process Control - .1%</li>
 	<li className="li1">Advanced Diagnostic Capabilities for Real-Time Feedback on Valve Performance</li>
 	<li className="li1">Advanced diagnostics and protocols-PID Control /HART /Modbus/Profibus</li>
 	<li className="li1">Asset monitoring - DCM Link Software</li>
</ul>
<h1>Applications:</h1>
<ul>
 	<li className="p1">Wellhead ESD Choke Valves - Angle body</li>
 	<li className="p1">Plunger/gas Lift - angle body chokes or D bodies</li>
 	<li className="p1">Separator Inlet Linear Control Valves - D-Bodies, HPT's</li>
 	<li className="p1">Separator Back pressure Control Valves - ET's</li>
</ul>
      <h1>Link:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-fl-fail-safe-linear-electric-actuator/" rel="noopener noreferrer">Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default BettisFL
