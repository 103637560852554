const SmartTank = () => {
  return (
    <div>
      <h1>Fisher Smart Tank Blanketing</h1>
      <ul>
        <li>Trend pressure regulator operation to ensure operability</li>
        <li>Be notified if the valve goes 100% open which could indicate loss of blanketing gas</li>    
        <li>Determine pressure regulator sticking or failure when used with a tank pressure transmitter or a Smart Wireless PVRV</li>    
      </ul> 
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/Regulators/Typical_Tank_Management_System_Animation/Typical_Tank_Management_System_Animation.html" rel="noopener noreferrer">System Animation</a></li>
      </ul>
    </div>
  )
}
  
export default SmartTank
