const EnardoES665 = () => {
  return (
    <div>
      <p>The Enardo™ ES665 Thief Hatch is a spring-loaded hatch designed for secure closure, certified for hazardous locations, and compatible with wireless monitoring systems. It features smart wireless position feedback, open status notifications, and the ability to track and timestamp thief hatch open events, ensuring safety and efficient monitoring in industrial settings.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Spring-loaded mechanism for secure closure</li>
 	<li class="li1">Ultra-low leakage, 0.10 SCFH<span class="Apple-converted-space">  </span>at 90% of set pressure at<span class="Apple-converted-space">  </span>ambient conditions</li>
 	<li class="li1">Minimizes emissions and product losses</li>
 	<li class="li1">“Sour gas” models available</li>
 	<li class="li1">Wireless position Feedback:
<ul class="ul1">
 	<li class="li1">Topworx Go Switch Model 73-12364-A2
<ul class="ul2">
 	<li class="li1">SPDT contacts rated 4amp / 120 VAC, 3amp / 24 VDC</li>
 	<li class="li1">CSA Cl 1, Div. 1, Groups A-D certification for hazardous locations</li>
</ul>
</li>
 	<li class="li1">Rosemount™ 702 Wireless Discrete Transmitter
<ul class="ul2">
 	<li class="li1">Dual discreet , detects momentary inputs &amp; counts</li>
 	<li class="li1">CSA-Intrinsically Safe certified transmitter</li>
</ul>
</li>
 	<li class="li1">Rosemount™ Wireless Gateway for wireless communication
<ul class="ul2">
 	<li class="li1">Zone 2 compatibility for hazardous locations</li>
</ul>
</li>
</ul>
</li>
 	<li class="li1">Pressure/Temperature monitoring with Rosemount™ 2088/644 devices</li>
 	<li class="li1">Open and latch status notification</li>
 	<li class="li1">Smart Wireless Position feedback</li>
 	<li class="li1">Timestamping and tracking of open events</li>
</ul>
      <h1>Application:</h1>
      <ul class="ul1">
 	<li class="li1">Renewable Energy Integration</li>
 	<li class="li1">Energy Storage Systems</li>
 	<li class="li1">Energy-Efficient Equipment</li>
 	<li class="li1">Methane Capture and Utilization</li>
 	<li class="li1">Waste Heat Recovery</li>
 	<li class="li1">Carbon Capture and Storage (CCS)</li>
 	<li class="li1">Water Conservation and Recycling</li>
 	<li class="li1">Sustainable Materials and Construction</li>
 	<li class="li1">Ecosystem Restoration</li>
 	<li class="li1">Monitoring and Reporting</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www3.emersonprocess.com/regulators/Animation/ES665/ES665%20Product%20Animation.html" target="_blank" rel="noopener noreferrer">Product Animation</a></li>
		<li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/tank-vents-and-hatches/spring-loaded-hatches/enardo-model-es-665-and-es-665-l-spring-loaded-thief-hatch/" target="_blank" rel="noopener noreferrer">Product Page</a></li>
		<li><a className="text-blue-500" href="https://www.emerson.com/en-us/catalog/enardo-model-es-665/" target="_blank" rel="noopener noreferrer">Emerson Catalog</a></li>
      </ul>
    </div>
  )
}
  
export default EnardoES665
