const DHV = () => {
  return (
    <div>
      <p>The valve line offered by DHV and Himark is designed and manufactured to the highest standards of quality, reliability, and safety, providing customers with optimal solutions for controlling and regulating fluid flow in various industries.</p>
      <h1>Features:</h1>
      <ul className="ul1">
        <li className="li1">DHV Valve Line:<span className="Apple-converted-space"> </span>
      <ul className="ul1">
        <li className="li1">Floating Ball Valves
      <ul className="ul2">
        <li className="li1">1 Piece / 2 Piece / 3 Piece body designs</li>
        <li className="li1">Raised Face Flanges / NPT Threaded / Socket Weld / Pup Ends</li>
        <li className="li1">Full and reduced bore</li>
        <li className="li1">Cast and forge steel components</li>
        <li className="li1">Anti-blowout stem design (API 608)</li>
        <li className="li1">Anti-static device</li>
        <li className="li1">Low emissions</li>
      </ul>
      </li>
      </ul>
      </li>
      </ul>
      <ul className="ul1">
        <li>
      <ul className="ul1">
        <li className="li1">Trunnion Ball Valves:
      <ul className="ul2">
        <li className="li1">2 Piece and 3 Piece bolted body type</li>
        <li className="li1">Full and reduced bore</li>
      </ul>
      </li>
      </ul>
      </li>
        <li className="li1">Ball Valve Size: ½ “ - 48”</li>
        <li className="li1">Design:</li>
        <li className="li1">Ansi 150# - 2500#</li>
        <li className="li1">API 6D, API 608</li>
        <li className="li1">Body: LF2 (A350)/A105, WCB (A216), LCC (A352), 316SS</li>
        <li className="li1">Trim (Ball/Stem): LF2 w/3Mil ENC, 316SS</li>
        <li className="li1">Seats+: Devlon, Viton GLT, PEEK, RTFE (Glass Filled), RTFE (PEEK Filled), Metal Seat Trim available</li>
        <li className="li1">Bubble tight seat seals</li>
        <li className="li1">Double Block and Bleed</li>
        <li className="li1">Anti-blowout stem design (API 608)</li>
        <li className="li1">Anti-static device</li>
        <li className="li1">Double piston / self-relieving seat designs (DBB / DIB1 / DIB2)</li>
        <li className="li1">Emergency grease stem sealing</li>
        <li className="li1">Emergency grease seat to ball sealing</li>
      </ul>
      <h1>Applications:</h1>
      <ul>
        <li>Upstream, Transmission pipelines, pumping, compression</li>
        <li>Re-injection units, terminals, pig traps, measuring stations, cryogenic-LNG</li>    
        <li>High temperature & abrasive services</li> 
      </ul> 
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.alteksupply.com/our-products/products/brand/dhv-1/">Altek Supply</a></li>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/">DHV & Himark Valve Line Product Page</a></li> 
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/floating-ball-valves/dhv-floating-ball-valve/">FDHV Floating Vall Valve</a></li>  
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/trunnion-ball-valves/dhv-trunnion-mounted-ball-valve/">DHV Trunnion Mounted Ball Valve</a></li>
      </ul> 
    </div>
  )
}
  
export default DHV
