const TankProtectionSolutions = () => {
  return (
    <div>
      <p>The Enardo 850/950 series of wirelessly-monitored pressure vacuum relief valves (PVRVs) that provide safety and emissions control by managing the pressure in storage tanks in the oil and gas, chemical, petrochemical, and pharmaceutical industries.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Model 850 &amp; 950– Pipe away</li>
 	<li class="li1">Model 950 – Vent to atmosphere</li>
 	<li class="li1">Sizes 2 through 12 available for different tank sizes</li>
 	<li class="li1">Leakage control: Designed to have leakage not exceeding 0.1 SCH (Standard Cubic Feet per Hour) at 90% of set point, ensuring effective sealing and preventing unnecessary losses.</li>
 	<li class="li1">Compatibility: Enardo™ Enhanced Sealing PVR valves can be used in conjunction with Vapor Recovery Units (VRUs) and/or Tank Blanketing Systems for enhanced performance</li>
 	<li class="li1">Fully Replaceable Pallet and Seat Assemblies: Easy maintenance and repair with fully replaceable pallet and seat assemblies</li>
</ul>
<ul class="ul1">
 	<li class="li1"><b>Option 1 - Wireless Position Feedback: Includes:</b>
<ul class="ul1">
 	<li class="li1">Rosemount™ 702 Wireless Transmitter, Topworx Go Switch Model #73-12364-A2, and Rosemount™ 3051 Pressure Transmitter with Flushing Ring for wireless monitoring of valve position</li>
</ul>
</li>
 	<li class="li1"><b>Option 2 - Wired Position Feedback:</b>
<ul class="ul1">
 	<li class="li1">Uses Topworx Go Switch Model #73 for wired position feedback</li>
 	<li class="li1">Overpressure Notification: Provides notifications in case of overpressure situations to ensure prompt action and prevent potential risks</li>
 	<li class="li1">Sticking or Failure Detection: Helps determine if the PVRV is sticking or has failed, aiding in troubleshooting and maintenance</li>
 	<li class="li1">Trending PVRV Operation: Enables monitoring and trending of PVRV operation to ensure continuous operability and optimal performance</li>
</ul>
</li>
</ul>
      <h1>Option 1- Wireless Position Feedback</h1>
      <ul class="ul1">
 	<li class="li1">Wellpad tank venting for pressure control</li>
 	<li class="li1">Integration with vapor recovery units (VRUs) to capture and recover emissions</li>
 	<li class="li1">Emissions monitoring for compliance with environmental regulations</li>
 	<li class="li1">Operational efficiency to optimize wellpad operations</li>
 	<li class="li1">Safety features to minimize environmental impact</li>
</ul>
      <h1>Links</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/tank-vents-and-hatches/pressure-vacuum-relief-valves/enardo-series-es-950-enhanced-sealing-high-performance-vent-to-atmosphere-pressure-vacuum-relief-val/">Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default TankProtectionSolutions
