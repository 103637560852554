
const Rosemount3051S = () => {
  return (
    <div>
      <p>The Rosemount 3051S Multivariable Transmitter is a versatile pressure measurement device that combines pressure, temperature, and flow measurements into a single unit. With its advanced technology and robust construction, it provides accurate and reliable data for various industrial applications, making it an essential tool for process control and optimization.</p>
      <h1>Features:</h1>
      <ul>
 	<li aria-level="1">Rangedown: Offers an impressive rangedown capability of up to 200:1, allowing for accurate measurements across a wide range of pressures</li>
 	<li aria-level="1">Measures multiple variables and standardizes on a single model number for streamline process and cost-efficiency</li>
 	<li aria-level="1">Reduces costs by eliminating the need for stacked transmitters and additional wiring</li>
 	<li aria-level="1">Primary elements available as assemble-to options for simple installation and commissioning</li>
 	<li aria-level="1">Ultra for Flow provides optimized flow readings with a ±0.04% of reading accuracy</li>
 	<li aria-level="1">Designed with built-in flow calculations for direct reporting of fully-compensated mass, energy or volumetric flow</li>
</ul>
      <h1>Specifications:</h1>
      <ul>
 	<li aria-level="1">Rangedown: Up to 200:1</li>
 	<li aria-level="1">Communication Protocol: 4-20 mA HART, Wireless HART®, FOUNDATION fieldbus</li>
 	<li aria-level="1">Measurement Range: Up to Up to 2000 psi (138 bar) differential, Up to 6092 psi (420 bar) static</li>
 	<li aria-level="1">Process Wetted Material: 316L SST, Alloy C-276, Tantalum, Gold-plated 316L</li>
 	<li aria-level="1">Diagnostics: Basic Diagnostics</li>
 	<li aria-level="1">Certifications / Approvals: NSF, NACE, hazardous location, see full specs for complete list of certifications</li>
 	<li aria-level="1">Designed with built-in flow calculations for direct reporting of fully-compensated mass, energy or volumetric flow</li>
 	<li aria-level="1">Multivariable design reduces pipe penetrations, impulse piping and connection systems for cost-savings</li>
</ul>
      <h1>Applications:</h1>
      <ul>
 	<li aria-level="1">Emissions Monitoring: Accurate measurements for controlling wellpad emissions</li>
 	<li aria-level="1">Energy Efficiency: Built-in flow calculations optimize energy consumption</li>
 	<li aria-level="1">Process Optimization: Comprehensive data for better process control and efficiency</li>
 	<li aria-level="1">Safety Enhancement: Diagnostics capabilities improve wellpad safety</li>
 	<li aria-level="1">Regulatory Compliance: Certifications ensure compliance with standards</li>
</ul>
<h1></h1>
      <ul>
      <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/measurement-instrumentation/pressure-measurement/pressure-transmitters-and-transducers/rosemount-3051s-multivariable-transmitter/">Rosemount 3051S Multivariable Transmitter - Spartan Controls</a></li>
      </ul>
    </div>
  )
}

export default Rosemount3051S
