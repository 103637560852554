const Rosemount214C = () => {
  return (
    <div>
      <p>The Rosemount™ 214C RTD Temperature Sensor by Spartan Controls is a reliable and precise temperature measurement device suitable for industrial applications. Its Resistance Temperature Detector (RTD) technology ensures accurate readings for effective process control. With its durable construction and compatibility with different environments, it offers reliable performance.</p>
      <h1>Specifications:</h1>
      <ul>
 	<li aria-level="1">Temperature Range: The sensor has a wire-wound element with a maximum ice-point resistance shift of 0.05 °C (0.020 ) after 1g vibration between 20 and 500 Hz for 150 hours, complying with IEC 60751:2008 standards</li>
 	<li aria-level="1">Lead Wires: It is equipped with 24 AWG wire that is FEP insulated and color-coded according to IEC 60751 standard</li>
 	<li aria-level="1">Time Constant / Response: The sensor has a maximum response time of 10.8 seconds to reach 50% sensor response when tested in flowing water, meeting the requirements of IEC 60751:2008</li>
 	<li aria-level="1">Stability: It offers a maximum ice-point resistance shift of +0.15 °C (0.059) following 1,000 hours at the maximum specified temperature, as measured per IEC 60751:2008 methods.</li>
 	<li aria-level="1">Insulation Resistance: The sensor provides a minimum insulation resistance of 1000 MQ when measured at 500 VDC at room temperature</li>
</ul>
<h1>Applications:</h1>
<ul>
 	<li aria-level="1">Renewable energy integration</li>
 	<li aria-level="1">Energy efficiency measures</li>
 	<li aria-level="1">Emissions monitoring and reduction</li>
 	<li aria-level="1">Carbon capture and storage (CCS)</li>
 	<li aria-level="1">Methane leak detection and control</li>
 	<li aria-level="1">Water conservation and management</li>
 	<li aria-level="1">Sustainable materials and waste management</li>
 	<li aria-level="1">Natural habitat preservation</li>
 	<li aria-level="1">Community engagement and stakeholder collaboration</li>
 	<li aria-level="1">Regulatory compliance and reporting</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/measurement-instrumentation/temperature-measurement/temperature-sensors/rosemount-214c-rtd-temperature-sensor/" target="_blank" rel="noopener noreferrer">Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default Rosemount214C
