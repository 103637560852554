import React from 'react';

const PIP = ({ circlex, circley, backToStart }) => {
  return (
    <div className='top-5 absolute left-5 w-1/5 text-right z-50 animate-fade cursor-pointer' onClick={backToStart}>
      <div className='border-8 border-solid border-gray-300 relative rounded-2xl overflow-hidden'>
        <div
          className='absolute rounded-full border-2 border-white border-solid'
          style={{
            top : circlex + '%', 
            left : circley + '%',
            width  : '11%',
            height: '0',            // Set height to 0
            paddingBottom: '10%',   // Use padding-bottom to control the height
            aspectRatio: '1 / 1'    // Maintain a 1:1 aspect ratio
          }}
        ></div>
        <img src="assets/stills/0_MainSiteZoomout_Still___compressed.jpg" alt='Home Background' className='w-full' />
      </div>
      <p className="text-white text-xl">Current Location</p>
    </div>
  )
}

export default PIP