const FB3000 = () => {
  return (
    <div>
      <p>The FB3000 Remote Terminal Unit (RTU) is a powerful controller designed for the oil and gas industry, offering scalability and expanded data capacity. It provides reliable automation and monitoring solutions for demanding operations in this sector.</p>
      <h1>Features:</h1>
      <ul>
 	<li aria-level="1">Built-in API, AGA, GPA, and ISO Calculations: The base firmware of the RTU includes calculations to adjust for operating conditions, ensuring reliable and accurate measurement of oil and/or gas fluids</li>
 	<li aria-level="1">API Allocation Measurement and API Crude Oil Custody Transfer Calculations: The RTU supports both API Allocation Measurement and API Crude Oil Custody Transfer Calculations, providing accurate measurement and custody transfer capabilities for liquid applications</li>
 	<li aria-level="1">Flexible Architecture: The RTU offers a flexible architecture that can be tailored to meet the specific needs of low-power applications as well as high-density 1/0 and distributed 1/0 requirements, all in one platform</li>
 	<li aria-level="1">Scalability with Extension Chassis: The extension chassis feature allows for scalability, enabling larger wellpad operators to utilize a single RTU with expanded 1/0 capability, providing a cost-effective and streamlined solution</li>
 	<li aria-level="1">FBxVue™ Software: The FBxVue™ software provides complete custom display capability, allowing users to configure and view the information they need, when they need it, enhancing visibility and control over operations</li>
 	<li aria-level="1">Firmware-based PIDs and Effects, Math, and Action Blocks: The RTU offers firmware-based PID controllers for dual-loop control with switchover logic, ensuring precise control of processes. Additionally, the Effects, Math, and Action Blocks provide simple programming functionality for enhanced customization and automation</li>
</ul>
	  <h1>Applications:</h1>
	  <ul>
 	<li aria-level="1">Pre-Built Oil Gas Applications to help automate common processes and workflows on the wellpad such as well testing and optimization techniques</li>
 	<li aria-level="1">Renewable energy integration</li>
 	<li aria-level="1">Energy efficiency measures</li>
 	<li aria-level="1">Emissions monitoring and reduction</li>
 	<li aria-level="1">Carbon capture and storage (CCS)</li>
 	<li aria-level="1">Methane leak detection and control</li>
 	<li aria-level="1">Water conservation and management</li>
 	<li aria-level="1">Sustainable materials and waste management</li>
 	<li aria-level="1">Natural habitat preservation</li>
 	<li aria-level="1">Community engagement and stakeholder collaboration</li>
 	<li aria-level="1">Regulatory compliance and reporting</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/remote-automation-scada/flow-computers-rtus/flow-computers-rtus/fb3000-remote-terminal-unit-rtu/">FB3000 Remote Terminal Unit I Spartan Controls</a></li>
      </ul>
    </div>
  )
}
  
export default FB3000
