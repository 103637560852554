import React, { useState, useEffect } from 'react';

function MouseTracker() {
  // State for storing mouse coordinates
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // Handler function for mouse movement
  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    // Add event listener when component mounts
    window.addEventListener("mousemove", handleMouseMove);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className='coordinates'>
      X: <span className='x'>{mousePosition.x}</span><br/>
      Y: <span className='y'>{mousePosition.y}</span><br/>
    </div>
  );
}

export default MouseTracker;
