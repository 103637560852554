const BettisFailSafe = () => {
  return (
    <div>
      <p>A non-intrusive intelligent fail-safe linear actuator for ON/OFF and modulation control in fail-safe applications. The robust and compact mechanical spring return design can be triggered to fail-safe action either on loss of power or signal.</p>
      <h1>Features:</h1>
      <ul>
 	<li aria-level="1">True mechanical fail safe (Compressed spring)</li>
 	<li aria-level="1">No separate UPS, battery backup or supercapacitors required</li>
 	<li aria-level="1">Low power draw, Samps idle / max .Samps at full speed and torque/thrust</li>
 	<li aria-level="1">Precise Process Control- .1%</li>
 	<li aria-level="1">Advanced diagnostics and protocols-PID Control /HART /Modbus/Profibus</li>
 	<li aria-level="1"><a href="https://www.emerson.com/en-us/catalog/emerson-dcmlink-software">Asset monitoring - DCM Link Software</a></li>
 	<li aria-level="1">On/off and continuous modulating control 59</li>
</ul>
      <h1>Applications:</h1>
      <ul>
 	<li aria-level="1">Wellhead choke valves- angle body and in-line</li>
 	<li aria-level="1">Plunger/gas Lift - angle body chokes or D bodies</li>
 	<li aria-level="1">Separator Inlet and BPCV Valves</li>
</ul>
<h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-fl-fail-safe-linear-electric-actuator/" target="_blank" rel="noopener noreferrer">Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default BettisFailSafe
