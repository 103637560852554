const FB1200 = () => {
  return (
    <div>
      <p>The FB1200 Multi-Run Explosion-Proof Flow line of electrochemical fixed-point toxic-gas detectors has been designed and engineered to provide continuous monitoring of potentially hazardous toxic gas leaks or oxygen depletion in the atmosphere. The product comes in both explosion-proof and intrinsically safe models to cater to different requirements.</p>
      <h1>Features:</h1>
      <ul>
 	<li aria-level="1">Hot-Swappable IS Sensor Module for Live Maintenance Without Declassification of Hazardous Areas</li>
 	<li aria-level="1">Hydrophobic Filter Easily Replaced Without Opening the Device or Use of Tools</li>
 	<li aria-level="1">Suitable for Outdoor Applications Requiring an IP66 Rating</li>
 	<li aria-level="1">Magnetic Switch and LEDs for User Interface</li>
 	<li aria-level="1">Can Use HART Devices and FlexVu® Universal Displays for Ul</li>
 	<li aria-level="1">Event and Calibration Logs Are Stored in Non-Volatile Memory</li>
 	<li aria-level="1">Two-Wire, Loop-Powered Device for Fault Tolerance</li>
 	<li aria-level="1">EMI/RFI Hardened (When Specific Model Selected)</li>
 	<li aria-level="1">Electrochemical Sensor Cell for Increased Accuracy, Stability, and Reliability</li>
 	<li aria-level="1">Sensor Factory Calibrated to Specific Gas Type and Target Concentration Level</li>
 	<li aria-level="1">Maximum Loop Resistance of 300 Ohms at 18 Vdc, 600 Ohms at 24 Vdc</li>
</ul>
      <h1>Applications:</h1>
      <ul>
 	<li aria-level="1">Real-Time monitoring and control</li>
 	<li aria-level="1">Remote automation</li>
 	<li aria-level="1">Data logging and reporting</li>
 	<li aria-level="1">Integration with environmental monitoring systems</li>
 	<li aria-level="1">Optimization of energy consumption</li>
 	<li aria-level="1">Compliance assurance</li>
 	<li aria-level="1">Maintenance and asset management</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/remote-automation-scada/flow-computers-rtus/flow-computers-rtus/fb1200-multi-run-explosion-proof-flow-computer-for-natural-gas/" target="_blank" rel="noopener noreferrer">Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default FB1200
