const FisherET = () => {
  return (
    <div>
      <p>The Fisher™easy-e™ ET Control Valve is a high-performance globe/angle valve designed for precision control in industrial applications. With a compact design, ease of maintenance, and a wide range of options, it offers reliable performance and efficient operation.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Certifications: SIL Capable, Fugitive Emission, NACE</li>
 	<li className="li1">Critical Service: Noise Abatement, Cavitation, Steam Condition, Erosive, Low Flow, Corrosive, General Service, Outgassing, Flashing</li>
 	<li className="li1">Flow Characteristics: Equal Percentage, Linear, Special Characterization, Quick Opening</li>
 	<li className="li1">Material: Carbon Steel, Stainless, Duplex, Alloy</li>
 	<li className="li1">Process Connection Type: Flanged, Butt Weld, RTJ, Threaded, Socket Weld</li>
 	<li className="li1">Operating Temperature: Cryogenic, High Temperature, Standard Temperature</li>
 	<li className="li1">Shutoff Class: Class IV (FCI 70-2), Class V (FCI 70-2), Class VI (FCI 70-2)</li>
 	<li className="li1">Valve Size: NPS 1 through NPS 30</li>
 	<li className="li1">ENVIRO-SEAL™ Packing Systems that Provide an Improved Stem Seal to Help Prevent the Loss of Process Fluid are Available: These packing systems feature PTFE or Graphite ULF Packing with Live-Loading: For reduced packing maintenance.</li>
 	<li className="li1">Increased Wear Resistance of Hardened Stainless-Steel Trim: Longer-lasting service</li>
 	<li className="li1">Unless Otherwise Moted: references are to NACE MR0175-2002. Optional materials are available to meet NACE MRO103 and NACE MR0175 / ISO 15156</li>
</ul>
      <h1>Applications:</h1>
      <ul className="ul1">
 	<li className="li1">Cavitation</li>
 	<li className="li1">Corrosive</li>
 	<li className="li1">Dirty Service</li>
 	<li className="li1">Erosive</li>
 	<li className="li1">Flashing</li>
 	<li className="li1">General Service</li>
 	<li className="li1">Low Flow</li>
 	<li className="li1">Noise Abatement</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/control-valves/globe-angle-valves/fisher-easye-et-control-valve/" target="_blank" rel="noopener noreferrer">Product Link</a></li>
      </ul>
    </div>
  )
}
  
export default FisherET
