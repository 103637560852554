const BettisRTSCompactQuater = () => {
  return (
    <div>
      <p>Automate valve operation in industrial settings, helping net zero wellpads reduce emissions and energy consumption. It has a compact design, advanced diagnostic capabilities, and is cost-effective for maintenance.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Local User Interface with Wireless Bluetooth Control Capability</li>
 	<li className="li1">Highly Configurable and Intelligent Actuator with Options for Continuous Modulation</li>
 	<li className="li1">Compact &amp; Lightweight with Corrosion Protection</li>
 	<li className="li1">Intelligent and Non-intrusive</li>
 	<li className="li1">On/Off (S2) and Continuous Modulation (S9)</li>
 	<li className="li1">Precise Control over the Flow of Fluids and Gases</li>
 	<li className="li1">Low power draw,.8mA idle / max 4.6 amp at full speed and torque/thrust</li>
 	<li className="li1">Advanced Optimization &amp; Control:</li>
 	<li className="li1">Precise Process Control - .1%</li>
 	<li className="li1">Certified Explosion and Weatherproof</li>
 	<li className="li1">Advanced Diagnostic Capabilities for Real-Time Feedback on Valve Performance</li>
 	<li className="li1">Protocols: Modbus™, HART™, PROFIBUS™, FOUNDATION Fieldbus™, PROFINET™</li>
 	<li className="li1">Bettis RTS CM Compact Quarter turn Electric Valve Actuator:
<ul className="ul1">
 	<li className="li1">Brushless DC Motor Technology</li>
 	<li className="li1">Rotatable in 90 Degree Steps with Remote Mounting Option</li>
 	<li className="li1">User Application Available for Android Devices</li>
</ul>
</li>
 	<li className="li1">Energy-Efficient Operation, Contributing to a Net Zero Wellpad</li>
 	<li className="li1">Robust Construction for Reliable Operation in Harsh Industrial Environments</li>
</ul>
      <h1>Applications:</h1>
      <ul>
 	<li className="p2">Wellhead Choke Valves: Angle body and in-line </li>
 	<li className="p2">Quarter Turn Ball, Butterfly and plug valves </li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/isolation/actuators/electric-actuators/bettis-rts-cm-compact-multi-turn-electric-actuator/" rel="noopener noreferrer">Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default BettisRTSCompactQuater
