const Rosemount928 = () => {
  return (
    <div>
      <p>The Rosemount™ 928 Wireless Gas Monitor is a cutting-edge product offered by Spartan Controls that enables remote and wireless monitoring of gas levels in industrial environments. It provides real-time data on gas concentrations, allowing for proactive detection and response to potential hazards, enhancing safety and operational efficiency.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Monitoring of Toxic Gases: Such as Hydrogen Sulfide, Carbon Monoxide, and Oxygen Depletion</li>
 	<li class="li1">Utilizes Secure and Cost-Effective WirelessHART Communication Protocol for Reliable Data Transmission</li>
 	<li class="li1">Hot-Swappable Electrochemical Sensor Module for Easy Field Replacement</li>
 	<li class="li2">Intrinsically Safe Power Module with Long Battery Life</li>
 	<li class="li1">High Accuracy in Gas Level Measurements</li>
</ul>
<h1>Applications:</h1>
<ul class="ul1">
 	<li class="li1">Ensuring worker safety</li>
 	<li class="li1">Minimizing environmental impact through gas leak detection</li>
 	<li class="li1">Optimizing processes for energy efficiency</li>
 	<li class="li1">Enabling remote monitoring</li>
 	<li class="li1">Reducing carbon footprint</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/measurement-instrumentation/wireless-systems/wireless-flame-gas-detection/rosemount-928-wireless-gas-monitor/" target="_blank" rel="noopener noreferrer">Rosemount™ 928 Wireless Gas Monitor Detection</a>
        </li>
      </ul>
    </div>
  )
}
  
export default Rosemount928

