const VaporRecoverySolutions = () => {
  return (
    <div>
      <p>The Fisher T208, Y696, and Type 1290 Regulators are versatile solutions for net zero wellpads. They offer precise pressure control, compatibility with sour gas service, and the ability to operate in low ambient temperatures, contributing to emissions reduction and optimized operations.</p>
      <h1>Features:</h1>
      <p>Fisher T208 Regulator:</p>
<ul class="ul2">
 	<li class="li1">Body Size: NPS ¾" up to 12" x 6"</li>
 	<li class="li1">Control Pressure Range: 0.25 in. w.c. to 7 psig</li>
 	<li class="li1">Maximum Inlet Pressure: 285 psig</li>
 	<li class="li1">Flow up to 2,811,000 SCH</li>
 	<li class="li1">Regulator Styles: Direct-Operated, Balanced, Pilot Operated</li>
 	<li class="li1">Low pressure setting and fast speed of response for quick and precise pressure control</li>
 	<li class="li1">Accurate Control with small lock-up pressure, ensuring precise regulation in various applications</li>
 	<li class="li1">Sour gas service compatibility, making it suitable for handling corrosive gases</li>
 	<li class="li1">Low ambient temperature construction up to -50°C, allowing it to operate in extreme cold environments</li>
</ul>
<p>Y696 Regulator:</p>
<ul class="ul1">
 	<li class="li1">Body Size: NPS ¾" up to 12" x 6"</li>
 	<li class="li1">Control Pressure Range: 0.25 in. w.c. to 7 psig</li>
 	<li class="li1">Maximum Inlet Pressure: 285 psig</li>
 	<li class="li1">Flow up to 2,811,000 SCH</li>
 	<li class="li1">Regulator Styles: Direct-Operated, Balanced, Pilot Operated</li>
 	<li class="li1">Low pressure setting and fast speed of response for quick and precise pressure control</li>
 	<li class="li1">Accurate Control with small lock-up pressure, ensuring precise regulation in various applications</li>
 	<li class="li1">Sour gas service compatibility, making it suitable for handling corrosive gases</li>
 	<li class="li1">Low ambient temperature construction up to -50°C, allowing it to operate in extreme cold environments</li>
</ul>
<p>Type 1290 Regulator:</p>
<ul class="ul1">
 	<li class="li1">Body Size: NPS ¾" up to 12" x 6"</li>
 	<li class="li1">Control Pressure Range: 0.25 in. w.c. to 7 psig</li>
 	<li class="li1">Maximum Inlet Pressure: 285 psig</li>
 	<li class="li1">Flow up to 2,811,000 SCH</li>
 	<li class="li1">Regulator Styles: Direct-Operated, Balanced, Pilot Operated</li>
 	<li class="li1">Low pressure setting and fast speed of response for quick and precise pressure control</li>
 	<li class="li1">Accurate Control with small lock-up pressure, ensuring precise regulation in various applications</li>
 	<li class="li1">Sour gas service compatibility, making it suitable for handling corrosive gases</li>
 	<li class="li1">Low ambient temperature construction up to -50°C, allowing it to operate in extreme cold environments</li>
</ul>
      <h1>Applications:</h1>
      <ul class="ul1">
 	<li class="li1">Pressure control in wellpad tanks</li>
 	<li class="li1">Regulation of gas flow in vapor recovery units (VRUs)</li>
 	<li class="li1">Fluid blending in tanks</li>
 	<li class="li1">Emissions reduction</li>
 	<li class="li1">Sour gas service compatibility</li>
 	<li class="li1">Low ambient temperature operation</li>
 	<li class="li1">Wellpad optimization</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/regulators/tank-blanketing/fisher-type-y696-vapor-recovery-regulator/" rel="noopener noreferrer">Product Page</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/Regulators/Type%201290%20Product%20Animation/Type%201290%20Product%20Animation.html" rel="noopener noreferrer">Type 1290 Regulator Example Animation</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/regulators/Animation/T208/T208%20Product%20Animation.html" rel="noopener noreferrer">T208 Series Example Animation</a></li>
      </ul>
    </div>
  )
}
  
export default VaporRecoverySolutions
