const DettronicsX3301 = () => {
  return (
    <div>
      <p>The X3301 delivers fast fire detection performance while limiting nuisance and false alarms. Versatile, robust, reliable, developed and built with the needs of hazardous and critical industrial process operators in mind.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Multiple sensitivity modes</li>
 	<li class="li1">Optical Integrity (oi®) for automatic fail-safe checks</li>
 	<li class="li1">Heated optics for adverse environments</li>
 	<li class="li1">Data logger with event time and date stamping (1500 events)</li>
 	<li class="li1">Multiple communication protocols (0-20 mA, relay, Modbus, HART, FDT®/DTM, and Eagle Quantum Premier®)</li>
 	<li class="li1">Diagnostic LED for local alarm and fault identification</li>
 	<li class="li1">Large capacity event log storage (1,500 events)</li>
</ul>
      <h1>Appllications:</h1>
      <ul class="ul1">
 	<li class="li1">Fire safety</li>
 	<li class="li1">Environmental protection</li>
 	<li class="li1">Asset protection</li>
 	<li class="li1">Worker safety</li>
 	<li class="li1">Regulatory compliance</li>
 	<li class="li1">Operational efficiency</li>
 	<li class="li1">Integration with control systems</li>
 	<li class="li1">Risk mitigation</li>
 	<li class="li1">Emergency preparedness</li>
 	<li class="li1">Contribution to net zero goal</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/fire-safety/fire-gas-detection/flame-detection/det-tronics-x3301/">Det-Tronics X3301 Multispectrum Flame Detectors Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default DettronicsX3301
