const BettisHydra = () => {
  return (
    <div>
      <p>The Bettis PressureGuard Hydraulic Emergency Shutdown System is a reliable and robust solution for ensuring quick and effective shutdowns in critical situations. With its advanced hydraulic actuation technology, it provides precise control and protection for a wide range of industrial applications.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Zero Emissions</li>
 	<li className="li1">Closed Loop System</li>
 	<li className="li1">Closes on Loss of Power to Solenoid and/or High/Low Pressure Changes</li>
 	<li className="li1">Advanced hydraulic actuation technology</li>
 	<li className="li1">High reliability for critical shutdowns</li>
 	<li className="li1">Precise control and protection</li>
 	<li className="li1">Compatibility with a wide range of industrial applications</li>
 	<li className="li1">Fully Integrated Assemblies: Actuator, valve, bracket, ISO test valves, and pressure pilots</li>
 	<li className="li1">Robust construction for durability in harsh environments</li>
 	<li className="li1">Torque Ranges:
<ul className="ul1">
 	<li className="li1">1,530lbs - 28,566lbs</li>
</ul>
</li>
</ul>
<h1>Applications:</h1>
<ul className="ul1">
 	<li className="li1">Wellhead ESDV Quarter Turn Ball Valves</li>
 	<li className="li1">Pipeline Operations</li>
 	<li className="li1">Tank Farm Operations</li>
 	<li className="li1">Terminal Operations</li>
 	<li className="li1">Pumping Stations</li>
 	<li className="li1">Compressor Stations</li>
</ul>
      <h1>Fully Integrated Assemblies:</h1>
      <ul>
        <li>Actuator, valve, bracket, ISO Test Valves, & Pressure Pilots</li>
      </ul>
      <h1>Sizes:</h1>
      <ul>
        <li>Bettis 35D</li>    
        <li>Bettis 50D</li>
        <li>Bettis 60D</li>    
        <li>Bettis 70D</li>
        <li>G2003.0-SR2-CW(11)-SC-2HP</li> 
        <li>G3003.5-SR4-CW(11)-SC-2HP</li> 
      </ul> 
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/actuators/hydraulic-actuators/bettis-pressureguard-hydraulic-emergency-shutdown-system/">Bettis PressureGuard Hydraulic Actuators - Spartan Controls </a></li>
      </ul>
    </div>
  )
}

export default BettisHydra