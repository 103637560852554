const GVSValve = () => {
  return (
    <div>
      <p>With a focus on reliability, efficiency, and safety, these Trunnion Ball Valves epitomize value by ensuring optimal fluid control and operational integrity, empowering businesses to achieve operational excellence and maximize productivity with confidence.</p>
      <h1>Features:</h1>
      <ul>
        <li>
          GVS Trunnion Ball ValvesBall Valves Sizes & Design
          <ul>
            <li>SPE, DIB-1, DIB-2, EDS</li>
          </ul>
        </li>
        <li>
          Ball Valves Sizes & Design:
          <ul>
            <li>2" - 42"</li> 
            <li>Ansi 150#-2500#</li>    
            <li>API 6D, API 608</li> 
          </ul>
        </li>
        <li>
          GVS Soft Seated:
          <ul>
            <li>Body: LF2(A350)/A105, WCB(A216), LCC(A352), 3165S</li>
          </ul>
        </li>
        <li>Trim (Ball/Stem): LF2 w/3Mil ENC, 316SS</li>
        <li>Recessed Ball Valves availabl</li>
        <li>Seats+: RTFE (Glass Filled), Viton GLT, RTFE (PEEK Filled), PEEK, Metal Seat for abrasive process +240°C, Metal Seat for high temperature process +350°C</li>
      </ul>
      <h1>Applications:</h1>
      <ul>
        <li>Upstream, Transmission pipelines, pumping, compression</li>
        <li>Re-injection units, terminals, pig traps, measuring stations, cryogenic-LNG</li>    
        <li>High temperature & abrasive services</li> 
        <li>Abrasive Services</li>
      </ul> 
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/trunnion-ball-valves/gvs-trunnion-mounted-severe-service-soft-seated-ball-valve/">GVS Trunnion Mounted Severe Service Soft Seated Ball Valve</a></li>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/trunnion-ball-valves/gvs-trunnion-mounted-soft-seated-ball-valves/">GVS Trunnion Mounted Soft Seated Ball Valves</a></li>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/trunnion-ball-valves/evs-trunnion-metal-seated-ball-valve/">EVS Trunnion Metal Seated Ball Valve</a></li>
      </ul>
    </div>
  )
}
  
export default GVSValve
