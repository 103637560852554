const DetTronicsGT3000 = () => {
  return (
    <div>
      <p>The Det-Tronics GT3000 line of electrochemical fixed-point toxic-gas detectors is designed and engineered to provide continuous monitoring of the atmosphere for potentially hazardous toxic gas leaks or oxygen depletion. Both explosion-proof and intrinsically safe models are available.</p>
      <h1>Features:</h1>
      <ul>
        <li>Hot-swappable IS sensor module for live maintenance without declassification of hazardous areas</li>
        <li>Hydrophobic filter easily replaced without opening the device or use of tools</li>  
        <li>Suitable for outdoor applications requiring an IP66 rating</li>
        <li>Magnetic switch and LEDs for user interface- also can use HART devices and FlexVu® Universal Displays for Ul</li>
        <li>Event and calibration logs are stored in non-volatile memory</li>
      </ul> 
      <h1>Technical:</h1>
      <ul>
        <li>Two-wire, loop-powered device for fault tolerance</li>
        <li>EMI/RFI hardened (when specific model selected)</li>  
        <li>Electrochemical sensor cell for increased accuracy, stability and reliability</li>
        <li>Sensor factory calibrated to specific gas type and target concentration level</li>
      </ul> 
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/fire-safety/fire-gas-detection/toxic-gas-detection/det-tronics-gt3000-toxic-gas-detector/">Det-Tronics GT 3000 w/GTS Sensor Toxic Gas Detector Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default DetTronicsGT3000
