const DettronicsLS2000 = () => {
  return (
    <div>
      <p>The Det-Tronics FlexSight™ LS2000 is designed to meet challenging hydrocarbon gas detection applications. This detector delivers an impressive field of view (FOV), advanced housing design, simplified mounting, and breakthrough alignment tolerance, all in a package designed with ease of maintenance, reliability, and low cost of ownership in mind.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Outstanding Misalignment Tolerance for High Vibration and Wind Applications</li>
 	<li class="li1">Third-Party Documented +/-0.8-Degree Misalignment Tolerance (+/- 2m Tolerance at 120m Range)</li>
 	<li class="li1">Performance Approved with 95% Obscuration</li>
 	<li class="li1">Technical:
<ul class="ul1">
 	<li class="li1">Multiple Communication Protocols, including 4-20 mA, Relay, Modbus, HART, FDT®/DTM, and Eagle Quantum Premier® (EQP) Safety System</li>
 	<li class="li1">Continuous Monitoring of Combustible Hydrocarbon Gas Concentrations in the Range of 0 to 5 LFL-Meters, over a Distance of 5 to 120 Meters</li>
</ul>
</li>
</ul>
      <h1>Applications:</h1>
      <ul class="ul1">
 	<li class="li1">Early detection of gas leaks</li>
 	<li class="li1">Enhanced safety</li>
 	<li class="li1">Real-time monitoring</li>
 	<li class="li1">Integration with safety systems</li>
 	<li class="li1">Reduced maintenance and cost</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/fire-safety/fire-gas-detection/combustible-gas-detection/det-tronics-flexsight-line-of-sight-infrared-hydrocarbon-gas-detector/">Det-Tronics FlexSight LS2000 Detector Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default DettronicsLS2000
